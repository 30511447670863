import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  Text,
  List,
  A
} from '../components/styled'

import kebabCase from "lodash/kebabCase"

export default ({ 
    data: {
      allMarkdownRemark: { group },
    },
  }) => {
  return (
  <Layout>  
    <SEO
      title="Tags - Projectival Blog"
      description=""
    />
  	<Container>
  		<Container.Inner narrow>
		    <Title as="h1">Tags</Title>
		    <Content full-width>
          <List>
            {group.map(tag => (
              <List.Item key={tag.fieldValue}>
                <A as={Link} to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </A>
              </List.Item>
            ))}
          </List>
        </Content>
		  </Container.Inner>
	  </Container>
  </Layout>
)}

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      filter: {
        fileAbsolutePath: {regex: "\/blog/"},
        frontmatter: {draft: {ne: true}}
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }    
`
